import axios from 'axios';
import _ from 'lodash';
import { getStore } from './hooks-for-redux';
let serviceContext = 'http://localhost:8080/agencyCloud';
// serviceContext = 'http://ec2-3-22-217-53.us-east-2.compute.amazonaws.com:8080/agencyCloud'
// serviceContext = 'http://ec2-3-135-200-53.us-east-2.compute.amazonaws.com:8080/agencyCloud';
// serviceContext = 'http://ec2-18-191-77-37.us-east-2.compute.amazonaws.com:8080/agencyCloud';
let serviceContextCloudBatch = 'http://localhost:8080/agencyCloudBatch';

//DEV with static ip old
// serviceContext = 'http://18.222.212.17:8080/agencyCloud'; //DEV
// serviceContextCloudBatch = 'http://18.222.212.17:8080/agencyCloudBatch'; //DEV

//DEV with static ip 2024
//https://ec2-13-58-251-121.us-east-2.compute.amazonaws.com:8080
serviceContext = 'http://13.58.251.121:8080/agencyCloud'; //DEV
serviceContextCloudBatch = 'http://13.58.251.121:8080/agencyCloudBatch'; //DEV

//DEV with hostname
serviceContext = 'https://devbackend.highiqagency.com/agencyCloud'; //DEV
serviceContextCloudBatch = 'https://devbackend.highiqagency.com/agencyCloudBatch'; //DEV

// serviceContext = 'http://18.217.26.69:8080/agencyCloudTest'; //DEV
// serviceContextCloudBatch = 'http://18.217.26.69:8080/agencyCloudBatchTest'; //DEV

// //DEV
// serviceContext =
//   'http://ec2-18-217-26-69.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //DEV
// serviceContextCloudBatch =
//   'http://ec2-18-217-26-69.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //DEV

// //UAT_OLD
// serviceContext =
//   'http://ec2-3-129-249-212.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //UAT_OLD
// serviceContextCloudBatch =
//   'http://ec2-3-129-249-212.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //UAT_OLD

// //UAT_OLD2
// serviceContext =
//   'http://ec2-18-216-166-158.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //UAT
// serviceContextCloudBatch =
//   'http://ec2-18-216-166-158.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //UAT

// UAT_OLD3_13_sep_2021
// serviceContext = 'http://3.129.253.51:8080/agencyCloud'; //UAT
// serviceContextCloudBatch = 'http://3.129.253.51:8080/agencyCloudBatch'; //UAT

// // UAT
// serviceContext = 'http://18.222.20.242:8080/agencyCloud'; //UAT
// serviceContextCloudBatch = 'http://18.222.20.242:8080/agencyCloudBatch'; //UAT

// //PROD
// serviceContext =
//   'http://ec2-3-22-204-95.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //PROD
// serviceContextCloudBatch =
//   'http://ec2-3-22-204-95.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //PROD

// //PROD 2024
serviceContext = 'https://prodbackend.highiqagency.com/agencyCloud'; //PROD
serviceContextCloudBatch = 'https://prodbackend.highiqagency.com/agencyCloudBatch'; //PROD

const APP_VERSION = '27-Sep';

let headers = { userId: 'shri' };
// headers = { userId: 'yogi', Pragma: 'no-cache' };
// headers = { userId: 1, Pragma: 'no-cache' };
headers = {};

// axios.defaults.headers.common['Pragma'] = 'no-cache';
// axios.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // const token = store.getState().session.token;
  // config.headers.Authorization =  token;
  const store = getStore().getState();
  if (_.has(store, 'user.data')) {
    const currentUser = store.user.data[0] || { userId: 1 };
    config.headers.userId = currentUser.userId;
  }
  return config;
});

const isMock = false;
export { serviceContext, serviceContextCloudBatch, isMock, APP_VERSION };
